import React from 'react'
import AddBoxIcon from '@mui/icons-material/AddBox';
import CustomInputInfoSingleLine from '../../../components/General/CustomInputInfoSingleLine';
import ReportService from '../../../../services/report';
import { CODES } from '../../../../utils/codesHTTP';
import DownloadIcon from '@mui/icons-material/Download';
import { setOpenLoader, setTitleLoader } from '../../../../config/redux/actions';
import { useDispatch, useSelector } from 'react-redux';

const SEE_SST = ({ informationInspection, stateActionPlan, }) => {
    const handleOpenLoader = (value) => dispatch(setOpenLoader(value));
    const dispatch = useDispatch();
    const options = informationInspection?.tipo?.opciones;

    const generateReportSST = async () => {
       
        try {
          //setTitleModalLoader('Generando Reporte de Sede...');
          handleOpenLoader(true);
          const result = await ReportService.generateReportSST(informationInspection);
          if (result.status === CODES.SUCCESS_200) {
            const blob = new Blob([result.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = "Inspección "+informationInspection?.tipo?.abreviatura+"_"+informationInspection?.id_inspeccion+"_"+informationInspection?.planta+"_"+informationInspection?.fecha+'.xlsx';
           
            link.click();
            URL.revokeObjectURL(url);
            //getPeople();
            
          } else {
              handleOpenLoader(false);
            //setTitleModalLoader(null);
          }
          handleOpenLoader(false);
          return []
        } catch (error) {
          console.log(`Error en generateReportSST: ${error}`);
        }
    }

    
    return (
        <section className='form-register inspection'>
            <div className='row justify-content-center'>
                <div className='col col-sm-12 col-md-6 col-lg-4'>
                    <CustomInputInfoSingleLine
                        title={'Area'}
                        value={informationInspection?.area}
                    />
                </div>
                <div className='col col-sm-12 col-md-6 col-lg-4'>
                    <CustomInputInfoSingleLine
                        title={'Sub-Area'}
                        value={informationInspection?.subArea}
                    />
                </div>
            </div>
            <div className='row'>
                <div className='col col-12 col-md-6 col-lg-4'>
                    <CustomInputInfoSingleLine
                        title={"Responsable Area"}
                        name={"supervisor_id"}
                        value={informationInspection?.supervisor || ""}
                    />
                </div>
                <div className='col col-12 col-md-6 col-lg-4'>
                    <CustomInputInfoSingleLine
                        title={"Responsable Inspección"}
                        value={informationInspection?.evaluador || ""}
                    />
                </div>
                <div className='col col-sm-12 col-md-6 col-lg-4 d-flex'>
                    <div style={{ width: "70%" }}>
                        <CustomInputInfoSingleLine
                            title={"Fecha Inspección"}
                            value={informationInspection?.fecha}
                        />
                    </div>
                    <div style={{ width: "40%" }}>
                        <CustomInputInfoSingleLine
                            value={informationInspection?.hora}
                            name={"hora"}
                        />
                    </div>
                </div>
            </div>
            {/* preguntas */}
            <div className="table-responsive">
                <table className="table table-bordered table-sm custom-table">
                    <tbody>
                        {informationInspection?.tipo?.tipo_modulos.map((module) => {
                            return (
                                <ModuleRow
                                    key={`module-${module.modulo.id_modulo}`}
                                    module={module.modulo}
                                    puntaje={module?.puntajeModulo}
                                    questions={module.preguntas}
                                    informationInspection={informationInspection}
                                />
                            )
                        })}
                    </tbody>
                </table>
            </div>
            {/* plan de accion */}
            <div className="table-responsive-sm">
                <table className="table table-bordered table-sm">
                    <thead className="border-white">
                        <tr>
                            <th className="text-center title-table-inspection" colSpan={666} >
                                PLAN DE ACCIÓN
                            </th>
                        </tr>
                        <tr>
                            <th className="text-center title-table-inspection" >SEMANA</th>
                            <th className="text-center title-table-inspection col-6" >ACCION</th>
                            <th className="text-center title-table-inspection" >FECHA FIN</th>
                            <th className="text-center title-table-inspection" >RESPONSABLE</th>
                            <th className="text-center title-table-inspection col-2 row-header-and-btn-end" >
                                <div className="text-header">ESTADO</div>
                                <button className="btn-outline-custom dark btn-header-end" type='button'>
                                    <AddBoxIcon fontSize='small' />
                                </button>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {informationInspection?.planes_accion?.map((plan, idxPlan) => {
                            return (
                                <tr key={idxPlan}>
                                    <td className="text-center col-1">{plan.semana}</td>
                                    <td>{plan.desPlanAccion}</td>
                                    <td className="text-center">{plan.fechaFin}</td>
                                    <td className="text-center">{plan.responsable}</td>
                                    <td className="row-header-and-btn-end">
                                        <div className="text-header">{stateActionPlan?.find(e => +e.value === +plan.estadoPlan_id)?.label}</div>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
                
            </div>
            <div className='d-flex justify-content-center gap-2 mb-3' style={{ marginTop: '0.5rem' }}>
                <button className='btn btn-lg btn-outline-success' title="Descargar excel" type="button" onClick={generateReportSST}>
                    Descargar excel <DownloadIcon />
                </button>
            </div>
            {/* Leyenda con las opciones */}
            <div className="mb-5 col-12 col-md-4"
                style={{
                    border: "solid 1px black",
                    borderRadius: "25px",
                    padding: "10px",
                }}
            >
                <table className="table table-bordered table-sm custom-table">
                    <thead>
                        <tr>
                            <th className="text-center" colSpan={5} style={{ backgroundColor: '#f0f0f0' }}>
                                Leyenda de Notas
                            </th>
                        </tr>
                        <tr>
                            {options?.map((row) => (
                                <th key={row.label} className="text-center col-1" style={{ width: "30px" }}>
                                    {row?.desc2}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            {options?.map((row) => (
                                <td key={row.label} className="text-center" style={{ width: "30px", backgroundColor: row.desc2 }}>
                                    {row?.label}
                                </td>
                            ))}
                        </tr>
                    </tbody>
                </table>
            </div>
        </section >
    )
}

const ModuleRow = ({ module, puntaje, questions, informationInspection, onChange }) => {
    return (
        <>
            <tr key={`module-${module.id_modulo}`}>
                <td className="text-center" colSpan={2} style={{ background: "#BFBFBF", fontSize: "13px", verticalAlign: 'middle', fontWeight: "bold" }}>{module.desModulo}</td>
                <td style={{ fontSize: "13px", verticalAlign: 'middle', fontWeight: "bold" }}>{`% Cumplimiento: ${puntaje || ''} %`}</td>
            </tr>
            {questions.map((question) => {
                const answer = informationInspection.respuestas.find(e => +e.pregunta_id === +question.id_pregunta);
                return (
                    <QuestionRow
                        key={`question-${question.id_pregunta}`}
                        question={question}
                        answer={answer}
                        onChange={onChange}
                    />
                );
            })}
        </>
    );
};

const QuestionRow = ({ question, answer, onChange }) => {
    const tipeRespuesta = question.tipo_respuesta;
    const options = question.opciones;
    return (
        <tr>
            <td>{question.desPregunta}</td>
            <td className='text-center'>{question.puntaje}</td>
            <td>
                {tipeRespuesta.desTipoRespuesta === 'opt' ?
                    <div className="form-control custom-radio-group in-line">
                        {options.map((option) => {
                            return (
                                <div key={option.value} className="custom-radio-option">
                                    <input
                                        type="radio"
                                        id={`${question.id_pregunta}_${option.value}`}
                                        value={option.value}
                                        checked={answer ? answer[tipeRespuesta.desc2] === option.value : false}
                                        readOnly
                                    />
                                    <label htmlFor={`${question.id_pregunta}_${option.value}`}>{option.label}</label>
                                </div>
                            )
                        })}
                    </div>
                    : <input
                        className="form-control custom-input"
                        placeholder="Ingrese valor"
                        type={tipeRespuesta.desTipoRespuesta}
                        value={answer ? answer[tipeRespuesta.desc2] : ""}
                        readOnly
                        disabled
                    />
                }
            </td>
        </tr>
    );
};

export default SEE_SST;