import axios from "axios";
import headerRequest from "../config/headers";

const API_URL = process.env.REACT_APP_API_URL_SST;
const prefix = 'export';
const url = window.location.pathname;
const commonParams = { url: url };

class ReportService {
 
    generateReportDTO(informationInspection) {
        const ruta = `${API_URL}/${prefix}/DTO`;
        const params = { ...commonParams };
        return axios.post(ruta, informationInspection, {
            headers: headerRequest(),
            params: params,
            responseType: 'blob'
        }).catch((error) => {
            return error;
        });
    }
    generateReportSST(informationInspection) {
        const ruta = `${API_URL}/${prefix}/SST`;
        const params = { ...commonParams };
        return axios.post(ruta, informationInspection, {
            headers: headerRequest(),
            params: params,
            responseType: 'blob'
        }).catch((error) => {
            return error;
        });
    }
    generateReport5S(informationInspection) {
        const ruta = `${API_URL}/${prefix}/5S`;
        const params = { ...commonParams };
        return axios.post(ruta, informationInspection, {
            headers: headerRequest(),
            params: params,
            responseType: 'blob'
        }).catch((error) => {
            return error;
        });
    }
}

export default new ReportService();
