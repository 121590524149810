import React, { useEffect, useState, } from 'react'
import { useParams, } from 'react-router-dom';
import { useDispatch, } from 'react-redux';
import { setTitleLoader, setOpenLoader } from '../../../../config/redux/actions';
import InspectionService from '../../../../services/inspection';
import GeneralService from '../../../../services/general';
import { titlesSpinner } from '../../../../message/titlesSpinner';
import DynamicTitle from '../../../../utils/DynamicTitle';
import SEE_DTO from '../DTO/SEE_DTO';
import SEE_SST from '../SST/SEE_SST';
import SEE_CINCO_S from '../5S/SEE_CINCO_S';

const SeeDetailInspection = () => {
    const { idType, idInspection } = useParams();
    const dispatch = useDispatch();
    const handleOpenLoader = (value) => dispatch(setOpenLoader(value));
    const handleTitleLoader = (value) => dispatch(setTitleLoader(value));

    const [informationType, setInformationType] = useState(null);
    const [informationInspection, setInformationInspection] = useState(null);
    const [aswers, setAswers] = useState(null);
    const [modules, setModules] = useState(null);
    const [suggestions, setSuggestions] = useState(null);
    const [actionPlans, setActionPlans] = useState(null);

    const [suggestionType, setSuggestionType] = useState([]);
    const [stateActionPlan, setStateActionPlan] = useState([]);

    useEffect(() => {
        init();
    }, []);

    const init = async () => {
        handleOpenLoader(true);
        Promise.all([
            showSuggestionType(),
            showStateActionPlan(),
        ]).then(() => {});
    }

    const showSuggestionType = async () => {
        try {
            setSuggestionType([]);
            const result = await GeneralService.showSuggestionType();
            setSuggestionType(result.data);
            return [];
        } catch (error) {
            console.log(`Error en Plant showAll: ${error}`);
        }
    }

    const showStateActionPlan = async () => {
        try {
            setStateActionPlan([]);
            const result = await GeneralService.showStateActionPlan();
            setStateActionPlan(result.data);
            return [];
        } catch (error) {
            console.log(`Error en Plant showAll: ${error}`);
        }
    }

    useEffect(() => {
        if (idType && idInspection)
            getInspection();
    }, [idType, idInspection]);

    const getInspection = async () => {
        try {
            handleOpenLoader(true);
            handleTitleLoader(titlesSpinner.inspection_data_list);

            setInformationInspection(null);
            setAswers(null);
            setModules(null);
            setSuggestions(null);
            setActionPlans(null);

            const queryParameters = { id_inspeccion: idInspection, tipo_id: idType };
            const result = await InspectionService.getInspection(queryParameters);
            setInformationInspection(result.data);
            setAswers(result.data.respuestas);
            setModules(result.data.tipo.tipo_modulos);
            setSuggestions(result.data.sugerencias);
            setActionPlans(result.data.planes_accion);
            setInformationType(result.data.tipo);
            return [];
        } catch (error) {
            console.log(`Error en Inspection: ${error}`);
        } finally {
            handleOpenLoader(false);
            handleTitleLoader(null);
        }
    }

    return (
        <div>
            <DynamicTitle title={`Inspección ${informationType?.abreviatura}`} />
            <h1 className='text-center title-page'>{informationType?.desTipo}</h1>
            <form className="px-3">
                {(+idType === 4) ?
                    <SEE_DTO
                        informationInspection={informationInspection}
                        suggestionType={suggestionType}
                        stateActionPlan={stateActionPlan}
                        suggestions={suggestions}
                    />
                    : (+idType === 2) ?
                        <SEE_SST
                            informationInspection={informationInspection}
                            stateActionPlan={stateActionPlan}
                        />
                        : (+idType === 5 || +idType === 6) ?
                            <SEE_CINCO_S
                                informationInspection={informationInspection}
                            />
                            : <h1 className='text-center title-page'>Pagina en mantenimiento</h1>
                }
            </form>
        </div>
    )
}

export default SeeDetailInspection;