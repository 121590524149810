import React, { useEffect, useState } from 'react'
import { useDispatch, } from 'react-redux';
import { setOpenLoader, setTitleLoader } from '../../../config/redux/actions';
import PlagiarismIcon from '@mui/icons-material/Plagiarism';
import SearchIcon from '@mui/icons-material/Search';
import InspectionService from '../../../services/inspection';
import { ButtonGroup, IconButton } from '@mui/material';
import { format, addDay, diffDays } from "@formkit/tempo"
import DynamicTitle from '../../../utils/DynamicTitle';
import CustomInput from '../../components/General/CustomInput';
import CustomTitleTable from '../../components/General/CustomTitleTable';
import { simpleAlerts } from '../../../utils/alerts';
import { validationMessages } from '../../../message/validations';

const columns = [
    { id: 'fecha', label: <CustomTitleTable title='FECHA' sort={false} />, },
    { id: 'hora', label: <CustomTitleTable title='HORA' sort={false} />, },
    { id: 'cantSugerencias', label: <CustomTitleTable title='SUGERENCAS' sort={false} />, },
    { id: 'cantPlanesAccion', label: <CustomTitleTable title='PLANES ACCIÓN' sort={false} />, },
    { id: 'cantRespuestas', label: <CustomTitleTable title='RESPUETAS' sort={false} />, },
    { id: 'evaluado', label: <CustomTitleTable title='EVALUADO' sort={false} />, },
    { id: 'supervisor', label: <CustomTitleTable title='SUPERVISOR' sort={false} />, },
    { id: 'planta', label: <CustomTitleTable title='PLANTA' sort={false} />, },
    { id: 'area', label: <CustomTitleTable title='ÁREA' sort={false} />, },
    { id: 'subArea', label: <CustomTitleTable title='SUB-ÁREA' sort={false} />, },
    { id: 'usuario', label: <CustomTitleTable title='USUARIO' sort={false} />, },
    { id: 'fechaMod', label: <CustomTitleTable title='REGISTRO' sort={false} />, },
    { id: 'actions', label: <CustomTitleTable title='actions' sort={false} />, },
];

const ViewInspection = () => {
    const dateLanguage = 'es';
    const day_limit = 15;
    const dispatch = useDispatch();
    const handleOpenLoader = (value) => dispatch(setOpenLoader(value));
    const handleTitleLoader = (value) => dispatch(setTitleLoader(value));

    const [types, setTypes] = useState([]);
    const [inspectionList, setInspectionList] = useState([]);
    const [fechaDesde, setFechaDesde] = useState(format(addDay(new Date(), -day_limit), "YYYY-MM-DD", dateLanguage));
    const [fechaHasta, setFechaHasta] = useState(format(new Date(), "YYYY-MM-DD", dateLanguage));

    useEffect(() => {
        init();
    }, []);

    const init = async () => {
        handleOpenLoader(true);
        handleTitleLoader("Buscando Inspecciones");
        Promise.all([
            getTypeInspection(),
            getListInspectionsByDate()
        ]).then(() => { handleOpenLoader(false); handleTitleLoader(null); });
    }

    const getTypeInspection = async () => {
        try {
            setTypes([]);
            const result = await InspectionService.getTypeInspection();
            setTypes(result.data);
            return [];
        } catch (error) {
            console.log(`Error en showShiftByPlant: ${error}`);
        }
    }

    const getListInspectionsByDate = async () => {
        try {
            if (diffDays(fechaHasta, fechaDesde) > day_limit) {
                simpleAlerts({ message: validationMessages.limit_days_inspection })
                return;
            }
            setInspectionList([]);
            const params = { fechaDesde, fechaHasta }
            const result = await InspectionService.getListInspectionsByDate(params);
            setInspectionList(result.data);
            return [];
        } catch (error) {
            console.log(`Error en showShiftByPlant: ${error}`);
        }
    }

    const searchInspectionDetail = async ({ data }) => {
        const { id_inspeccion, id_tipo } = data;
        // Guardar los datos grandes en el almacenamiento local
        localStorage.setItem('inspectionData', JSON.stringify(data));
        // Abrir una nueva ventana con la URL deseada
        const url = `/inspection-detail/${id_tipo}/${id_inspeccion}`;
        window.open(url, '_blank');
    };

    return (
        <form className="px-3">
            <DynamicTitle title="Ver Inspecciones" />
            <h1 className='text-center title-page'>Listado de inspecciónes</h1>
            <section className='form-register'>
                <div className='row justify-content-center gap-2 align-items-end'>
                    <div className='col-md-4 col-sm-8'>
                        <CustomInput
                            title={"Desde"}
                            type={"date"}
                            value={fechaDesde}
                            name={"fecha"}
                            onChange={(e) => setFechaDesde(e.target.value)}
                            required={true}
                        />
                    </div>
                    <div className='col-md-4 col-sm-8'>
                        <CustomInput
                            title={"Hasta"}
                            type={"date"}
                            value={fechaHasta}
                            name={"fecha"}
                            onChange={(e) => setFechaHasta(e.target.value)}
                            required={true}
                        />
                    </div>
                    <div className='col-1'>
                        <button
                            className='btn btn-sm btn-outline-primary ms-2'
                            type='button'
                            onClick={getListInspectionsByDate}
                            title='Buscar Inspecciones'
                        >
                            <SearchIcon fontSize='small' />
                        </button>
                    </div>
                </div>
                <div className="accordion container" id="types-inspection">
                    {types?.map((type) => {
                        const inspections = inspectionList?.filter(e => +e.id_tipo === +type.id_tipo);
                        const numberInspections = inspections.length;
                        return (
                            <div className="accordion-item" key={`title-type-inspection-${type.id_tipo}`}>
                                <h2 className="accordion-header" id={`header-inspection-${type.id_tipo}`}>
                                    <button
                                        className="accordion-button btn-sm collapsed"
                                        style={{
                                            fontSize: '14px',
                                            padding: '8px',
                                        }}
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target={`#collapse-inspection-${type?.id_tipo}`}
                                        aria-expanded="false"
                                        aria-controls={`collapse-inspection-${type?.id_tipo}`}
                                    >
                                        <span>{type.desTipo}</span>
                                        &nbsp;&nbsp;&nbsp;
                                        <span style={{ backgroundColor: 'black', color: 'white', borderRadius: '10px', padding: '2px 8px', }}>
                                            {numberInspections}
                                        </span>
                                    </button>
                                </h2>
                                <div
                                    id={`collapse-inspection-${type?.id_tipo}`}
                                    className="accordion-collapse collapse"
                                    aria-labelledby={`header-inspection-${type?.id_tipo}`}
                                    data-bs-parent="#types-inspection"
                                >
                                    <div className="accordion-body">
                                        <div className="table-responsive-sm">
                                            <table className="table table-bordered table-sm">
                                                <thead className='sticky-top table-dark header-table z-1'>
                                                    <tr>
                                                        {columns?.map((col) => {
                                                            return (
                                                                <th
                                                                    key={`header-${col.id}`}
                                                                    className={`${col.align} ${col.order && 'sortable'} text-center th-table-head`}
                                                                // style={{ width: `${col.width}px`, maxWidth: `${col.width}px`, }}
                                                                // onClick={() => col.order && handleSortTable(col.id)}
                                                                >
                                                                    {col.label}
                                                                </th>
                                                            )
                                                        })}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {inspections?.map((row, idxRow) => {
                                                        return (
                                                            <tr key={"row" + idxRow}>
                                                                {columns.map((col, idxCol) => {
                                                                    const value = row[col.id];
                                                                    return (
                                                                        <td key={'col' + idxCol}>
                                                                            {col.id === "actions" ?
                                                                                <ButtonGroup>
                                                                                    <IconButton
                                                                                        aria-label="upload"
                                                                                        size="small"
                                                                                        title="Buscar detalle"
                                                                                        onClick={() => searchInspectionDetail({ data: row })}
                                                                                    >
                                                                                        <PlagiarismIcon fontSize='inherit' color={'primary'} />
                                                                                    </IconButton>
                                                                                </ButtonGroup>
                                                                                : value
                                                                            }
                                                                        </td>
                                                                    )
                                                                })}
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </section>
        </form>
    )
}

export default ViewInspection